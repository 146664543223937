.box-container {
    width: 70%;
    margin: 20px auto;
  }
   
  .custom-slider {
    width: 100%;
    height: 20px;
    background: #eee;
    opacity: 0.8;
    outline: none;
    transition: 0.3s;
    appearance: none;
    -webkit-appearance: none;
  }
  
  .custom-slider:hover {
    opacity: 1;
  }
  
  .custom-slider::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background: #01488b;
    cursor: pointer;
  }
  .custom-slider::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: #01488b;
    cursor: pointer;
  }
